.Navbar {
  position: relative;
  padding: 24px 40px 24px;
  text-align: center;

  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--grey-100);
}

.Navbar .items-group {
  display: flex;
  align-items: center;
  margin-left: 120px;
  gap: 14px;
}

.Navbar.logging-out::after,
.Navbar.logging-in::after {
  /* Transparent overlay on Page to prevent interactions */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background);
  opacity: 0.8;
  z-index: 10;
}

.Navbar.logging-out .pluggy.logout-button.button.loading {
  z-index: 20;
}

.Navbar .action-group {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .Navbar .items-group {
    margin-left: 10%;
  }
}

@media screen and (max-width: 500px) {
  .Navbar {
    padding: 16px;
    display: grid;
    /* make a grid of 2 columns and 2 rows */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'logo actions'
      'items items';
  }

  .Navbar .logo {
    grid-area: logo;
    display: flex;
    justify-content: flex-start;
  }

  .Navbar .logo > img {
    object-fit: contain;
  }

  .Navbar .items-group {
    margin-top: 4px;
    grid-area: items;
    margin-left: 0;
  }

  .Navbar .action-group {
    grid-area: actions;
    margin-left: 0;
    display: flex;
    justify-content: flex-end;
  }
}
