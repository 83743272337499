.ui.popup.visible {
  padding: 4px 8px;
  border-radius: 4px;
  border: none;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.ui.popup.visible::before {
  display: none;
}

.ui.popup.visible .content {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
