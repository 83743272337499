.Page {
  position: relative;
}

.Page.with-navbar {
  padding: 55px 0;
}

.Page.fullscreen.ui.container,
.Page.fullscreen {
  /* use !important to override Semantic defauls */
  width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.Page.logging-in::after,
.Page.logging-out::after {
  /* Transparent overlay on Page to prevent interactions */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background);
  opacity: 0.8;
  z-index: 10;
}

.Page.logging-in .login-form-button-container .pluggy.ui.button.loading,
.Page.logging-in .LoginForm .SocialLoginButton.loading {
  z-index: 20;
}
