.InvestmentSkeleton .title {
  width: 16%;
}

.InvestmentSkeleton .investment-info-container {
  display: flex !important;

  align-items: center;
}

.InvestmentSkeleton .investment-info-container .text-container {
  margin-left: 16px;
  width: 100%;
}
