.Item.ui.segment {
  border-radius: 4px;
  border: 1px solid var(--grey-100);
  padding: 20px 16px 16px 16px;
  margin: 12px 0;
}

.Item.ui.segment.selected {
  border: 1px solid var(--grey-900);
  background-color: var(--grey-50);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

.Item.ui.segment.multi-selected {
  background: var(--grey-50);
  border-color: var(--primary);
}

.Item.ui.segment .ui.grid > .row,
.Item.ui.segment .ui.grid > .row > .column {
  padding: 0 !important;
}

.Item.ui > .ui.grid {
  /* overriding semantic defaults */
  margin: 0 !important;
}

.Item.ui.segment .item-divider {
  width: 100%;
  border: 1px solid var(--grey-100);
  margin-top: 20px;
  margin-bottom: 16px;
}

.Item .item-loader,
.Item .item-status-loader {
  top: 36px;
  left: 22px;
}

.Item.ui.segment .connector-logo {
  margin-right: 16px;
}

.Item.ui.segment .connector-logo,
.Item.ui.segment .connector-logo img {
  width: 32px;
  height: 32px;
  /* prevent from shrink */
  min-width: 32px;
  min-height: 32px;
  border: solid 1px var(--lighter-grey);
  background: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.Item.ui.segment .row > .item-column-name {
  color: var(--text);
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.Item.ui.segment .item-column .item-status-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Item.ui.segment .item-column .item-status-container .ui.dropdown {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.Item.ui.segment .item-column-date.column {
  width: unset !important;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px !important;
  overflow: hidden;
  white-space: nowrap;
}

.Item.ui.segment .item-column-date.column .ColumnHeader {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;

  margin: 0 8px 0 0;
}

.last-date-updated {
  text-overflow: ellipsis;
  overflow: hidden;
}

.Item.ui.segment .menu.transition.visible .option .text {
  font-size: 14px;
  line-height: 16px;
}

/* cancel button */
.ui.modal > .actions .button.ui.pluggy:first-of-type {
  margin-right: 16px;
}

/* dropdown */
.Item.ui.segment .item-column .menu.visible {
  margin-top: 10px;
  padding: 8px;
  border: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.Item.ui.segment .item-column .menu.visible .item {
  border-radius: 4px;
  padding: 8px 12px !important;
}

.Item.ui.segment .item-column .menu.visible .item .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.Item.ui.segment .item-column-name .radio.checkbox.pluggy {
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  .ItemContainer .items-card-container .ListSection .row .Item {
    display: inline-block;
    width: 276px;
    margin: 16px 10px 0 0;
  }
}
