.ui.table {
  border: 1px solid var(--grey-100);
}

.ui.table .table-header tr,
.ui.table .table-header tr th {
  /* override semantic styles */
  background-color: var(--grey-100) !important;
  color: var(--grey-900);
  padding: 16px;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.ui.table tbody tr {
  background-color: var(--white);
}

.ui.table tbody tr:nth-child(2n) {
  background-color: var(--grey-50);
}

.ui.table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  padding: 16px;
  background-color: transparent;
}

.ui.table tbody tr .description-cell span {
  margin-right: 8px;
}
