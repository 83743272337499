.DeleteIcon.Icon {
  cursor: pointer;
}

.DeleteIcon.Icon.disabled {
  cursor: not-allowed;
}

.DeleteIcon.Icon:hover path {
  fill: var(--icon-hover);
}

.DeleteIcon.Icon path {
  fill: var(--icon-active);
}

.DeleteIcon.Icon.disabled path {
  fill: var(--icon-disabled);
}
