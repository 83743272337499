.MainSection {
  margin: 0;
  padding: 0;
}

.MainSection .ui.breadcrumb .active.section {
  font-weight: 500;
}

.MainSection .ui.header {
  font-family: var(--secondary-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}

.MainSection .subtitle {
  max-width: 1100px;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  /* note: using important to override semantic styles */
  margin: 16px 0 0 0 !important;
  color: var(--grey-700);
}

@media screen and (max-width: 480px) {
  .MainSection {
    margin-top: 0;
  }
}
