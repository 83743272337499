.ItemProductsContainer > div.product h3 {
  display: flex;
  align-items: center;

  font-family: var(--secondary-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  margin-bottom: 16px;
}

.ItemProductsContainer > div.product > h3 .Tag {
  margin-left: 8px;
}

.ItemProductsContainer div.product:not(:first-of-type) .title-container h3,
.ItemProductsContainer > div.product:not(:first-of-type) > h3 {
  margin-top: 28px;
}

.ItemProductsContainer .error-container {
  width: 100%;
  height: 400px;
  margin-top: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ItemProductsContainer .error-container img.ui {
  max-width: 386px;
  max-height: 120px;
}

.ItemProductsContainer .error-container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  margin-bottom: 32px;
}

.ItemProductsContainer .item-products-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.ItemProductsContainer .item-products-header .ui.button.pluggy {
  margin-left: auto;
}

.ItemProductsContainer .item-products-header .title-container h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.ItemProductsContainer .title-container {
  display: flex;
  align-items: baseline;
  gap: 12px;
}
