.ListSection {
  position: relative;
}

.ListSection .list-section-degradee {
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  width: calc(100% - 16px);
  height: 46px;

  bottom: 30px;
  left: 0;
}
@media screen and (max-width: 480px) {
  .ListSection .list-section-degradee {
    display: none;
  }
}
