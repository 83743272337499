.LoadingPage {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoadingPage .progress-bar {
  position: relative;

  width: 100px;
  height: 4px;

  margin-top: 20px;
  background-color: var(--pink-100);
  border-radius: 10px;
}

/* loading animation */
@keyframes progress-bar-loading {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}

.LoadingPage .progress-bar::before {
  content: '';
  position: absolute;

  width: 100px;
  height: 4px;

  border-radius: 10px;
  background-color: var(--pink-400);
  -webkit-animation: progress-bar-loading 5s ease;
  animation: progress-bar-loading 5s ease;

  animation-iteration-count: infinite;
}
