.ItemProductsContainerJsonView {
  border: 1px solid var(--grey-100);
  padding: 20px 16px;
  border-radius: 4px;
}

.ItemProductsContainerJsonView .product-selector {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 20px;
}

.ItemProductsContainerJsonView .json-view {
  background: var(--grey-800);
  color: var(--white);
  padding: 24px;
  border-radius: 4px;
}
