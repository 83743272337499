* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

:root {
  --font-family: Work Sans, -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-monospace: 'Inconsolata', 'Consolas', 'Droid Sans Mono', 'Monaco',
    'Courier New', monospace;

  --secondary-font-family: Poppins, Work Sans, -apple-system, system-ui,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  --text-link: var(--blue-400);
  --text: var(--grey-700);
  --white: #fff;
  --lighter-grey: #efefef;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font-family);
}

p {
  color: var(--text);
}

a,
.link {
  color: var(--text-link);
  cursor: pointer;
  text-decoration: none;
}

a:hover,
.link:hover {
  color: var(--text-link);
  text-decoration: var(--text-link-hover);
}

.ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Skeleton styles */
.skeleton {
  /* workaround to fit the animation in every size/form */
  overflow: hidden;

  background: linear-gradient(90deg, #e0e0e0 -11.63%, #f5f5f5 97.82%);
  position: relative;
  border-radius: 2px;
}

@keyframes skeleton-loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.skeleton::after {
  content: '';
  position: absolute;

  top: 0;
  height: 100%;

  background: linear-gradient(90deg, #e0e0e0 -11.63%, #f5f5f5 97.82%);
  -webkit-animation: skeleton-loading 3s ease-out;
  animation: skeleton-loading 3s ease-out;

  animation-iteration-count: infinite;
}
