.ItemSkeleton {
  padding: 20px;
  border: 1px solid var(--grey-100);
  border-radius: 4px;
}

.ItemSkeleton.ui.segment .row {
  flex-wrap: nowrap;
  padding: 0;
}

.ItemSkeleton.ui.segment .row > .column {
  padding: 0 !important;
}

.ItemSkeleton .grid.skeleton-grid {
  padding: 0;
  border: none;
  /* note: using !important to override semantic */
  margin: 0 !important;
  border-radius: 4px;
}

.ItemSkeleton .divider {
  border: 1px solid var(--grey-100);
  margin: 20px 0;
}

.ItemSkeleton .grid.skeleton-grid .image-column.column {
  display: flex !important;
}

.ItemSkeleton .grid.skeleton-grid .skeleton.item-image {
  width: 32px;
  height: 32px;
  /* prevent from shrink */
  min-width: 32px;
  min-height: 32px;

  margin-right: 16px;
  border-radius: 50%;
}

.ItemSkeleton .grid.skeleton-grid .image-text-container {
  width: 100%;
}

.ItemSkeleton .grid.skeleton-grid .image-text-container .item-text {
  width: 60%;
  height: 10px;
}

.ItemSkeleton
  .grid.skeleton-grid
  .image-text-container
  .item-text:first-of-type {
  width: 80%;
  margin-bottom: 8px;
}

.ItemSkeleton .grid.skeleton-grid .tag-column {
  display: flex !important;
  justify-content: flex-end;
}

.ItemSkeleton .grid.skeleton-grid .tag-column .item-tag {
  width: 60%;
  height: 32px;
}

.ItemSkeleton .grid.skeleton-grid .skeleton.text.connection {
  width: 60%;
  height: 10px;
}

@media screen and (max-width: 480px) {
  .ui.segment.ItemSkeleton {
    display: inline-block;
    width: 276px;
    margin: 16px 10px 0 0;
  }
}
