.Icon.ArrowIcon path {
  fill: var(--blue-400);
}

.Icon.ArrowIcon:hover path {
  fill: var(--blue-400);
}

.Icon.ArrowIcon.disabled path {
  fill: var(--blue-400);
}
