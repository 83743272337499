.ProductSkeleton .product-info-container {
  display: flex !important;

  align-items: center;
}

.ProductSkeleton .product-info-container .text-container {
  margin-left: 16px;
  width: 100%;
}
