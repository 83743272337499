.ItemConnecting {
  position: relative;

  padding: 20px 16px 18px;

  border: 1px solid var(--grey-100);
  box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 4px;
}

.ItemConnecting .progress-bar {
  transition: width 2s ease-in-out;

  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: var(--primary);
}

.ItemConnecting .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ItemConnecting .header > div {
  width: 50%;
}

.ItemConnecting .header .status-tag-container {
  display: flex;
  justify-content: flex-end;
}

.ItemConnecting .header .status-tag-container .Tag.pluggy {
  white-space: nowrap;
}

.ItemConnecting:not(:first-of-type) {
  margin-top: 12px;
}

.ItemConnecting .item-connecting-header {
  display: flex;
  align-items: center;
}

.ItemConnecting .connector-logo {
  margin-right: 16px;
}

.ItemConnecting .connector-logo,
.ItemConnecting .connector-logo img {
  width: 32px;
  height: 32px;
  /* prevent from shrink */
  min-width: 32px;
  min-height: 32px;
  border: solid 1px var(--lighter-grey);
  background: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.ItemConnecting .item-connector-name {
  max-width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.ItemConnecting .line {
  margin-top: 20px;
  margin-bottom: 14px;
  border: 1px solid var(--grey-100);
}

.ItemConnecting .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ItemConnecting .card-footer .ui.button.pluggy.link.medium .content {
  display: flex;
  align-items: flex-end;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  color: var(--blue-400);
}

.ItemConnecting
  .card-footer
  .ui.button.pluggy.link.medium
  .content
  .ArrowIcon {
  margin-left: 10px;
}
