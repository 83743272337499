.ItemsContainer {
  /* calculated by hand: (screen height - navbar height) */
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0;
  margin-bottom: 0;
}

.ItemsContainer .EmptyItem {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 120px;

  border: 1px dashed var(--grey-100);
}

.ItemsContainer .product-container {
  width: 100%;
  padding: 32px 28px 40px 0;
  overflow-y: auto;
  overflow-x: visible;
}

.ItemsContainer .product-container::-webkit-scrollbar {
  width: 0;
  display: none;
  background: transparent;
}

.ItemsContainer .product-container .MainSection {
  margin-top: 0;
  margin-bottom: 33px;
  background-color: var(--grey-50);
  padding: 16px 20px;
  border-radius: 4px;
}

.ItemsContainer .items-card-container {
  position: relative;
  width: 100%;
  max-width: 450px;

  flex-shrink: 1.5;
  border-left: 1px solid var(--grey-100);

  padding-left: 24px;
  padding-bottom: 40px;
}

.ItemsContainer .items-card-container .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 32px;
  padding-bottom: 16px;
}

.ItemsContainer .items-card-container .header-container h3 {
  font-size: 18px;
  font-family: var(--secondary-font-family);

  margin: 0;
}

.ItemsContainer .items-count {
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);

  margin-left: 4px;
}

.ItemsContainer .mocked-items-list {
  margin-top: 32px;
}

.ItemsContainer .mocked-items-list .ItemSkeleton {
  margin-top: 0;
}

.ItemsContainer .items-list-container {
  position: relative;

  /* calculated by hand, is the 100% - the navbar height and some menu things like connect button, etc */
  /* note: if we change sizes or rework the design, we should change this measure */
  height: calc(100% - 142px);
  overflow: auto;
}

.ItemsContainer .items-list-container.with-scrollbar {
  padding-right: 8px;
}

.ItemsContainer .items-list-container.with-scrollbar::-webkit-scrollbar {
  width: 8px;
  padding-right: 10px;
}

.ItemsContainer .items-list-container.with-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.ItemsContainer .items-list-container.with-scrollbar::-webkit-scrollbar-thumb {
  background: var(--grey-100);
  width: 4px;
  border-radius: 4px;
  min-height: 100px;
}

.ItemsContainer .items-list-container:not(.with-scrollbar)::-webkit-scrollbar {
  width: 0;
  display: none;
  background: transparent;
}

.ItemsContainer .items-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ItemsContainer .items-list .ItemSkeleton {
  margin-top: 0;
  margin-bottom: 0;
}

.ItemsContainer .items-list > div:first-of-type {
  margin-top: 0;
}

.ItemsContainer .items-card-container .scroll-shadow {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
}

.ItemsContainer .select-mode-header {
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  gap: 16px;
  align-items: center;
}

.ItemsContainer .select-mode-header .actions {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.ItemsContainer .select-mode-header .Icon.DeleteIcon {
  margin-right: 28px;
}

@media screen and (max-width: 1080px) {
  .ItemsContainer {
    max-height: unset;
    flex-direction: column-reverse;
  }

  .ItemsContainer .items-card-container {
    max-width: 100%;
    padding: 0 0 16px;
    border: none;
  }

  .ItemsContainer .product-container {
    padding: 0 0 42px;
  }

  .ItemsContainer .items-card-container .header-container {
    margin-top: 24px;
  }

  .ItemsContainer .items-list {
    height: unset;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    overflow-x: auto;
  }

  .ItemsContainer .items-list-container.with-scrollbar {
    padding-right: 0;
  }

  .ItemsContainer .items-list-container.with-scrollbar .items-list {
    padding-bottom: 8px;
  }

  .ItemsContainer .items-list::-webkit-scrollbar {
    height: 0;
    display: none;
  }

  .ItemsContainer
    .items-list-container.with-scrollbar
    .items-list::-webkit-scrollbar {
    height: 8px;
    padding-bottom: 10px;
    display: block;
  }

  .ItemsContainer
    .items-list-container.with-scrollbar
    .items-list::-webkit-scrollbar-track {
    background: transparent;
  }

  .ItemsContainer
    .items-list-container.with-scrollbar
    .items-list::-webkit-scrollbar-thumb {
    background: var(--grey-100);
    height: 4px;
    border-radius: 4px;
    min-width: 100px;
  }

  .ItemsContainer .items-list-container {
    height: unset;
  }

  .ItemsContainer
    .items-list-container
    .Item.ui.segment
    .item-column
    .menu.visible {
    margin-top: -54px;
    margin-right: 24px;
  }

  .ItemsContainer .items-list > .Item,
  .ItemsContainer .items-list > .ItemSkeleton {
    min-width: 350px;
    /* needed to allow click items because parent has pointer-events none */
  }

  .ItemsContainer .items-list > div.Item,
  .ItemsContainer .items-list > div.ItemConnecting {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ItemsContainer .items-list > div.ItemConnecting:not(:last-of-type),
  .ItemsContainer .items-list > div.Item:not(:last-of-type) {
    margin-right: 12px;
  }

  .ItemsContainer .mocked-items-list {
    margin-bottom: -32px;
  }

  .ItemsContainer .ListSection.mocked-items-list .ui.grid .row .list-item {
    display: flex;
    flex-direction: row;
  }

  .ItemsContainer
    .ListSection.mocked-items-list
    .ui.grid
    .row
    .list-item
    div.ItemSkeleton,
  .ItemsContainer .items-list > div.ItemSkeleton {
    min-width: 300px;
    height: 130px;
    margin-right: 12px;
  }

  .ItemsContainer .items-card-container .scroll-shadow {
    display: none;
  }
}
