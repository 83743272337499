.NavbarItem {
  border: none;
  background-color: transparent;
  color: var(--grey-800);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.NavbarItem.active,
.NavbarItem:hover {
  color: var(--grey-800);
  background-color: var(--indigo-100);
}
