.StepsToConnect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 360px;
  border: 1px dashed var(--grey-100);
  padding: 24px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.StepsToConnect .header {
  color: var(--grey-700);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  margin-bottom: 24px;
}

.StepsToConnect .steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.StepsToConnect .steps .step {
  position: relative;
  display: flex;
  align-items: center;

  background-color: var(--indigo-100);
  padding: 8px;
  border-radius: 4px;

  font-family: var(--font-family);
  color: var(--grey-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.StepsToConnect .steps .step .step-number {
  font-family: var(--secondary-font-family);
  color: var(--indigo-300);
  font-size: 24px;
  font-weight: 600;

  margin-right: 8px;
}

/* first step */
.StepsToConnect .steps .step:nth-of-type(1) {
  margin-bottom: 19px;
  margin-left: 35px;
}

/* add icon in step */
.StepsToConnect .steps .step:nth-of-type(1)::before {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: -20px;
  width: 20px;
  height: 18px;
  background-image: url('./images/sparkle-left.png');
  background-repeat: no-repeat;
  background-size: contain;
}

/* second step */
.StepsToConnect .steps .step:nth-of-type(2) {
  margin-left: -40px;
}

/* third step */
.StepsToConnect .steps .step:nth-of-type(3) {
  margin-top: 15px;
}

/* add icon in step */
.StepsToConnect .steps .step:nth-of-type(3)::before {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  right: -25px;
  width: 20px;
  height: 18px;
  background-image: url('./images/sparkle-right.png');
  background-repeat: no-repeat;
  background-size: contain;
}
