.ItemProductsContainerSkeleton.ui.segment {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}

.ItemProductsContainerSkeleton.ui.segment .row {
  padding: 0;
}

.ItemProductsContainerSkeleton .skeleton-container .grid.skeleton-grid {
  padding: 20px 0;
  border: 1px solid var(--grey-100);
  /* note: using !important to override semantic */
  margin: 0 0 32px 0 !important;
  border-radius: 4px;
}

.ItemProductsContainerSkeleton .title {
  height: 20px;
  width: 20%;

  margin-bottom: 20px;
}

.ItemProductsContainerSkeleton .text,
.ItemProductsContainerSkeleton .text.title {
  width: 30%;
  height: 10px;
}

.ItemProductsContainerSkeleton .text.title {
  margin-bottom: 8px;
}

.ItemProductsContainerSkeleton .text {
  width: 50%;
}

.ItemProductsContainerSkeleton .text.show-more {
  width: 80%;
}

.ItemProductsContainerSkeleton .divider {
  width: 100%;
  border: 1px solid var(--grey-100);
  margin: 22px 16px;
}

.ItemProductsContainerSkeleton .skeleton.image {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}
