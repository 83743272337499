.HomePage.ui.container {
  /* leaving 40px for each side */
  width: calc(100% - 80px);
  margin: 0 auto;
  padding: 0;
}

.HomePage .Section {
  border: none;
}

.HomePage .content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1080px) {
  .HomePage.ui.container {
    margin: 0;
  }
}
