.Toasts {
  z-index: 998;
  position: fixed;
  max-height: 100vh;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;

  padding: 10px;
  padding-left: var(--container-side-margin);

  display: flex;

  /* Bottom left corner positioning */
  left: 0;
  bottom: 0;
}

.Toasts .toast {
  z-index: 999;
  margin-bottom: 20px;
  margin-left: 20px;
}
